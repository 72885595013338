body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.jsScroll {
	opacity: 0;
	transition: opacity 500ms;
}

.jsScroll.scrolled {
	opacity: 1;
}


.scrolled.fadeIn {
	animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.galleryHover3:hover {
	transform: translate3d(0, -7px, -5px);
	cursor: pointer;
}

.growButton {
	position: absolute;
	cursor: pointer
}

.growButton:hover {
	transform: scale(1.1)	
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Dropdown Menu */

.navbar .dropdown:hover > .dropdown-menu {
	display: block; /* this makes the dropdown menu stay open while hovering it */
	min-width: auto;
	animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
		0% {
				opacity: 0;
		}

		100% {
				opacity: 1;
		}
}

.navbar .dropdown-toggle {
	cursor: pointer;
}

.navbar .dropdown-toggle:focus { 
	outline: 0;
}

.navbar .dropdown-menu {
	cursor: pointer;
	margin-top: 0;
	border: none;
}

.dropdownItem {
	padding: 5px;
	display: block;
	width: 100%;
	padding: .25rem 1.5rem;
	clear: both;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.dropdown-divider {
	width: 100%;
	height: 1px;
	border: none;
}

.dropdown-menu li {
	position: relative;
}
.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
	display: block;
}

.dropdownItemTextBoldHover:hover {
	font-weight: bold
}

.dropdownItemTextUnderHover:hover {
	text-decoration:	underline
}

.dropdownItemDivHoverLighter:hover {
	background-image: linear-gradient(rgba(255, 255, 255, 0.2) 0 0);
}

.dropdownItemDivHoverDarker:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
}